import React from "react";
import Img from 'gatsby-image'
import styled from "styled-components";
import Container from "../layouts/Container";
import {media} from "../styles/MediaMixins";
import Paragraph from "../paragraph/Paragraph";
import Row from "../layouts/Row";
import RowItem from "../layouts/RowItem";


const SolutionTitle = styled.h2`
    color: #FFFFFF;
    font-size: ${48 / 16}rem;
    font-weight: 500;
    line-height: ${62 / 16}rem;
    margin-top: ${10 / 16}rem;
    margin-right: ${20 / 16}rem;
    ${media.touch`text-align: center`}
`

const SolutionSubheader = styled(Paragraph)`
    text-align: left;
    color: #FFFFFFB3;
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    letter-spacing: 0;
    font-weight: 500;
    margin-top: ${5 / 16}rem;
    margin-bottom: ${12 / 16}rem;
`

const SolutionParagraph = styled(Paragraph)`
    text-align: left;
    color: #FFFFFF;
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    font-weight: 700;
    margin-bottom: ${32 / 16}rem;
`

const Result = styled.div`
    color: #FFFFFF;    
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    font-weight: bold;
    ${media.touch`
        margin-top: ${65 / 16}rem;
        margin-bottom: ${31 / 16}rem;
    `};
    ${media.desktop`
        margin-top: ${115 / 16}rem;
    `};
`

const SolutionContainer = styled(Container)`
    background: ${props => props.background};
    ${media.touch`
        padding-top: ${21 / 16}rem;
        padding-bottom: ${31 / 16}rem;
    `};
    ${media.desktop`
        min-height: ${866 / 16}rem;
        height: auto;
        padding-top: 1rem;
        padding-bottom: 1rem;
    `};
`

const ImgRowItem = styled(RowItem)`
    ${media.desktop`
        margin-left: 70px;
    `}
`

export default ({header, subheader, background, content, result, image}) => {
    return (
        <SolutionContainer background={background}>
            <Row breakpoint={'desktop'}>
                <RowItem maxWidth={641} shortMaxWidth={641} breakpoint={'desktop'}>
                    <SolutionTitle>{header || 'The Solution'}</SolutionTitle>
                    <SolutionSubheader>{subheader}</SolutionSubheader>
                    {!Array.isArray(content) ?
                        <SolutionParagraph>{content}</SolutionParagraph> :
                        content.map((line, index) => <SolutionParagraph key={index}>{line}</SolutionParagraph>)}
                    <Result>{result}</Result>
                </RowItem>
                <ImgRowItem maxWidth={876} shortMaxWidth={876} breakpoint={'desktop'}>
                    <Img imgStyle={{height: 'auto'}} alt={'Solution section'} fluid={image.childImageSharp.fluid}/>
                </ImgRowItem>
            </Row>

        </SolutionContainer>
    );
};
