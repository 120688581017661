import React from "react";

import Img from 'gatsby-image'

import Paragraph from "../paragraph/Paragraph";
import Container from "../layouts/Container";
import RowItem from "../layouts/RowItem";
import styled from "styled-components";
import {media} from "../styles/MediaMixins";
import Row from "../layouts/Row";

const ProblemContainer = styled(Container)`
    ${media.desktop`
        min-height: ${471 / 16}rem;
        height: ${471 / 16}rem;
    `};
    ${media.touch`
        padding-bottom: ${44 / 16}rem;
    `};
`

const ProblemTitle = styled.h2`
    color: #515B6E;
    font-size: ${48 / 16}rem;
    font-weight: bold;
    line-height: ${62 / 16}rem;
    margin-right: ${20 / 16}rem;
    ${media.desktop`
        margin-top: ${10 / 16}rem;;
    `};
    ${media.touch`
        text-align: center;
        margin-bottom: ${11 / 16}rem;
        margin-top: ${31 / 16}rem;
    `};
`

const ProblemParagraph = styled(Paragraph)`
    text-align: left;
    color: #33333366;
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    font-weight: 500;
    ${media.touch`
        margin-bottom: ${31 / 16}rem;
    `}
`

const TextRowItem = styled(RowItem)`
    ${media.desktop`
        margin-right: 20px;
    `}
`

const ImgRowItem = styled(RowItem)`
    ${media.desktop`
        margin-left: 20px;
    `}
`

export default ({header, content, image}) => {
    return (
        <ProblemContainer>
            <Row>
                <TextRowItem maxWidth={702}>
                    <ProblemTitle>{header || 'The Problem'}</ProblemTitle>
                    {!Array.isArray(content) ?
                        <ProblemParagraph>{content}</ProblemParagraph> :
                        content.map((line, index) => <ProblemParagraph key={index}>{line}</ProblemParagraph>)
                    }
                </TextRowItem>
                <ImgRowItem maxWidth={703} shortMaxWidth={703}>
                    <Img imgStyle={{height: 'auto'}} alt={'Problem section'} fluid={image.childImageSharp.fluid}/>
                </ImgRowItem>
            </Row>
        </ProblemContainer>
    );
};