import React, {memo} from "react";

import Img from 'gatsby-image'
import Paragraph from "../paragraph/Paragraph";
import styled from "styled-components";
import Container from "../layouts/Container";
import {media} from "../styles/MediaMixins";
import Row from "../layouts/Row";
import OrderedRowItem from "../layouts/OrderedRowItem";

const ChallengeContainer = styled(Container)`
    min-height: ${751 / 16}rem;
    background-color: #F9F9F9;
    ${media.touch`padding-bottom: ${31 / 16}rem`};
`

const ChallengeTitle = styled.h2`
    color: #515B6E;
    font-size: ${48 / 16}rem;
    font-weight: bold;
    line-height: ${62 / 16}rem;
    margin-top: ${10 / 16}rem;
    margin-right: ${20 / 16}rem;
    text-align: right;
    ${media.touch`
        text-align: center;
        margin-bottom: ${11 / 16}rem;
    `};
`

const ChallengeParagraph = styled(Paragraph)`
    color: #33333366;
    font-size: ${18 / 16}rem;
    line-height: ${32 / 16}rem;
    font-weight: 500;
    text-align: right;
    margin-bottom: ${40 / 16}rem;
    ${media.touch`
        text-align: center;
        text-align: left;
        margin-bottom: ${20 / 16}rem;
    `};
`

const TextRowItem = styled(OrderedRowItem)`
    ${media.desktop`
        margin-left: 20px;
    `}
`

const ImgRowItem = styled(OrderedRowItem)`
    ${media.desktop`
        margin-right: 20px;
    `}
`

export default memo(({header, content, image}) => {
    return (
        <ChallengeContainer>
            <Row>
                <ImgRowItem smOrder={2} lgOrder={1} maxWidth={767} shortMaxWidth={767} breakpoint={'desktop'}>
                    <Img imgStyle={{height: 'auto'}} alt={'Challenge section'} fluid={image.childImageSharp.fluid}/>
                </ImgRowItem>
                <TextRowItem smOrder={1} lgOrder={2} maxWidth={702} shortMaxWidth={702} breakpoint={'desktop'}>
                    <ChallengeTitle>{header || 'The Challenge'}</ChallengeTitle>
                    {!Array.isArray(content) ?
                        <ChallengeParagraph>{content}</ChallengeParagraph> :
                        content.map((line, index) => <ChallengeParagraph key={index}>{line}</ChallengeParagraph>)
                    }
                </TextRowItem>
            </Row>
        </ChallengeContainer>
    );
});
