import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import ProblemSection from "../components/portfolio/ProblemSection";
import ChallengeSection from "../components/portfolio/ChallengeSection";
import SolutionSection from "../components/portfolio/SolutionSection";
import PortfolioPreview from "../components/portfolio/PortfolioPreview";
import FooterDivider from "../components/FooterDivider";
import Title from "../components/title/Title";
import GenericHeader from "../components/header_section/GenericHeader";
import Container from "../components/layouts/Container";
import styled from "styled-components";
import {fluidSizing, fluidTypography, media} from "../components/styles/MediaMixins";

const SeeMoreTitle = styled(Title)`
    ${media.desktop`
        margin-top: ${100 / 16}rem;
        margin-bottom: ${49 / 16}rem;
    `};
    ${media.touch`
        margin-top: ${40 / 16}rem;
        margin-bottom: ${20 / 16}rem;
    `};
`

const Platforms = styled.div`
    color: #FFFFFFCC;
    font-weight: 500;
    margin-top: ${10 / 16}rem;
    margin-bottom: ${15 / 16}rem;  
    ${fluidTypography(12, 15)};
    ${fluidSizing('line-height', 15, 17)};
`

class PortfolioCasePage extends React.Component {

  render() {
    const {data} = this.props;
    const {markdownRemark: post} = data;
    const {frontmatter: meta} = post;

    let projects = data.allMarkdownRemark.edges.map(remark => (
      {
        image: remark.node.frontmatter.preview_image_square,
        title: remark.node.frontmatter.title,
        subtitle: remark.node.frontmatter.subtitle,
        path: remark.node.frontmatter.path,
        platforms: remark.node.frontmatter.platforms
      }
    ));
    projects = projects.filter(p => meta.path !== p.path).sort(() => 0.5 - Math.random());
    projects = projects.slice(0, 2);
    const description =
      <>
        <div style={{opacity: '60%', marginTop: -15}} className={'has-text-weight-medium'}>
          {meta.subtitle}
        </div>
        <Platforms>
          {meta.platforms.join('\u00A0\u00A0\u00A0\u00A0')}
        </Platforms>
        {meta.header_subtitle}
      </>;
    return (
      <Layout
        meta_image={meta.title_image.childImageSharp.fluid.src}
        meta_image_width={meta.title_image.childImageSharp.fluid.presentationWidth}
        meta_image_height={meta.title_image.childImageSharp.fluid.presentationHeight}
        meta_keyword={`it portfolio, cypress portfolio, app development portfolio, case studies for apps, applications portfolio`}
        meta_description={`Check out our portfolio and learn what spheres we are specialized in. Our mobile applications help businesses in different industries.`}
        local_title={'Portfolio - Cypress'} hasBackgroundColor={true}>
        <GenericHeader title={meta.title}
                       subtitle={description}
                       title_image={meta.title_image}
                       title_image_mobile={meta.title_image_mobile}
                       backgroundColor={meta.header_color}
                       no_right_padding={meta.no_right_padding}/>

        <ProblemSection content={meta.content.problem.description}
                        image={meta.content.problem.image}/>

        <ChallengeSection className={'has-generic-padding'}
                          content={meta.content.challenge.description}
                          image={meta.content.challenge.image}/>

        <SolutionSection className={'has-generic-padding'}
                         background={meta.header_color}
                         subheader={meta.content.solution.subtitle}
                         content={meta.content.solution.description}
                         result={meta.content.solution.result}
                         image={meta.content.solution.image}/>

        <Container>
          <SeeMoreTitle>
            Also Checkout These Projects
          </SeeMoreTitle>
        </Container>

        <PortfolioPreview
          portfolios={[
            {
              image: projects[0].image,
              platforms: projects[0].platforms,
              title: projects[0].title,
              subtitle: projects[0].subtitle,
              link: `/portfolio/${projects[0].path}/`
            },
            {
              image: projects[1].image,
              platforms: projects[1].platforms,
              title: projects[1].title,
              subtitle: projects[1].subtitle,
              link: `/portfolio/${projects[1].path}/`
            }
          ]}/>
        <FooterDivider marginTop={70} marginBottom={50}/>
        <br/>
      </Layout>
    )
  }
}

PortfolioCasePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PortfolioCasePage

export const portfolioCasePageQuery = graphql`
  query PortfolioCasePage($id: String!, $maxHeaderImg: Int!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        title
        subtitle
        header_color
        platforms
        platforms_color
        path
        header_subtitle
        no_right_padding
        content {
          problem {
            description
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 703, srcSetBreakpoints: [564, 703], sizes: "(max-width: 1366px) calc(564px), (min-width: 1367px) calc(703px), 100vw") {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          challenge {
            description
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 767, srcSetBreakpoints: [614, 767], sizes: "(max-width: 1366px) calc(614px), (min-width: 1367px) calc(767px), 100vw") {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          solution {
            subtitle
            description
            result
            image {
              childImageSharp {
                fluid(quality: 100, maxWidth: 876, srcSetBreakpoints: [700, 876], sizes: "(max-width: 1366px) calc(700px), (min-width: 1367px) calc(876px), 100vw") {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        title_image {
          childImageSharp {
            fluid(quality: 100, maxWidth: $maxHeaderImg, srcSetBreakpoints: [749, $maxHeaderImg], sizes: "(max-width: 1366px) calc(749px), (min-width: 1367px) calc(916px), 100vw") {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title_image_mobile {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1024, srcSetBreakpoints: [1024]) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___path]}, filter: {frontmatter: {templateKey: {eq: "portfolio-case-page"}}}) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            platforms
            path
            preview_image_square {
              childImageSharp {
                fluid(quality: 100, maxWidth: 252, srcSetBreakpoints: [252]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
